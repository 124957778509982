import React, { } from 'react';
import Editor from "react-markdown-editor-lite";
import ReactMarkdown from "react-markdown";
import "react-markdown-editor-lite/lib/index.css";
import api, { PostImageParameters, GetImageParameters } from '../api';
import MarkdownIt from 'markdown-it';

const mdParser = new MarkdownIt({
  breaks: true,
  linkify: true,
});

// 新的窗口打开链接
var defaultRender = mdParser.renderer.rules.link_open || function (tokens, idx, options, env, self) {
  return self.renderToken(tokens, idx, options);
};

mdParser.renderer.rules.link_open = function (tokens, idx, options, env, self) {
  var aIndex = tokens[idx].attrIndex('target');

  if (aIndex < 0) {
    tokens[idx].attrPush(['target', '_blank']);
  } else {
    const attrs = tokens[idx].attrs
    if (attrs) {
      attrs[aIndex][1] = '_blank';
    }
  }
  return defaultRender(tokens, idx, options, env, self);
};


interface PageState {

}

interface Props {
  value?: string
  onChange?: (value?: string | {}) => void;
}

type PageProps = Props

interface Page {

}


export default class JJ_MarkdownEditor extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {

  }

  _postImage = async (param: PostImageParameters) => {
    const res = await api.PostImage(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }


  _getImage = async (param: GetImageParameters) => {
    const res = await api.GetImage(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }


  render() {


    return (
      <Editor
        // ref={node => (this.mdEditor = node || undefined)}
        value={this.props.value}
        style={{
          height: "500px"
        }}
        onChange={({ text, html }) => {
          console.log('onChange')
          console.log('text', text)
          console.log('html', html)
          this.props.onChange && this.props.onChange(text)
        }}
        // renderHTML={text => <ReactMarkdown source={text} />}
        renderHTML={text => mdParser.render(text)}
        // | 当没有定义上传函数时，默认插入的图片 | String | `''` |  |
        imageUrl={'https://static.fa.oveser.com/imgs/vegetarian.png'}
        // 接受上传的图片类型，例如`.jpg,.png` | String | `''` | |
        imageAccept={`.jpg,.png`}
        onImageUpload={async (file: File) => {
          const postImageRes = await this._postImage({ file })
          const imageRes = await this._getImage({ id: postImageRes.id })
          return imageRes.url
        }}

      />
    )

  }

}
