interface ConfigType {
    /**
    * 域名
    */
    REACT_APP_API: string
}

const parse = (text: any) => {

    try {
        return JSON.parse(text)
    } catch (error) {
        return false
    }

}

//配置開發模式
class Config implements ConfigType {
    REACT_APP_API = String(process.env['REACT_APP_API'])
    REACT_APP_STATIC = String(process.env['REACT_APP_STATIC'])
    REACT_APP_DELETE_DISABLED = parse(process.env['REACT_APP_DELETE_DISABLED'])
    REACT_APP_SIGNUP_FORM_HOST = String(process.env['REACT_APP_SIGNUP_FORM_HOST']).replace(/\/$/, '')
    REACT_APP_DEV_ENV = process.env['REACT_APP_DEV_ENV']
    REACT_APP_NAME = String(process.env['REACT_APP_NAME']).replace(/\/$/, '')
}

const config = new Config()
export default config


